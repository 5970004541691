import { Directive, Input, TemplateRef, ViewContainerRef, effect } from '@angular/core';
import { TypeService } from './type-management.service';
import { UserType } from './user.type';

@Directive({
    selector: '[appHasType]',
    standalone: true,
})
export class HasTypeDirective {
    private currentType: string | string[] | null = null;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private roleService: TypeService
    ) {}

    @Input() set appHasType(type: string) {
        this.currentType = type;
        this.updateView();
    }

    @Input() set appHasAtLeastOneType(types: string[]) {
        this.currentType = types;
        this.updateView();
    }

    private updateView() {
        // Clear the view container to avoid duplications
        this.viewContainer.clear();

        if (this.currentType) {
            if (Array.isArray(this.currentType)) {
                // Check for at least one matching type
                if (this.roleService.supportedPlans(this.currentType as UserType[])) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }
            } else {
                // Check for a single type
                if (this.roleService.supportedPlans([this.currentType] as UserType[])) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }
            }
        }
    }
}
