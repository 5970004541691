import { NgIf } from '@angular/common';
import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    WritableSignal,
    effect,
    signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { AppsComponent } from 'app/layout/common/apps/apps.component';
import { CurrentOrgComponent } from 'app/layout/common/current-org/current-org.component';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { OrganisationFilterComponent } from 'app/layout/common/organisation-filter/organisation-filter.component';
import { SchemesComponent } from 'app/layout/common/schemes/schemes.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { QuickChatComponent } from 'app/modules/chat/quick-chat/quick-chat.component';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { DenseService } from '../dense/dense.service';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    styleUrls: ['./classy.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        MatButtonModule,
        MatIconModule,
        LanguagesComponent,
        CurrentOrgComponent,
        FuseFullscreenComponent,
        UserComponent,
        NgIf,
        RouterOutlet,
        AppsComponent,
        SchemesComponent,
        OrganisationFilterComponent,
        QuickChatComponent,
        UserComponent,
    ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: WritableSignal<User> = signal(null);
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    displayHeaderAndNav: boolean = true;
    displayProjects: boolean = false;
    showNavigation: boolean = true;
    version: string;
    showAreaInput = false;

    navigationAppearance: 'default' | 'thin' = 'default';

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _denseService: DenseService,
        private router: Router
    ) {
        this.version = environment.version;

        effect(() => {
            this.displayHeaderAndNav = this._denseService.displayHeaderAndNav;
            this.displayProjects = this._denseService.displayProjectFilter;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
            this.user.set(user);
        });
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
                const isScreenDense = !matchingAliases.includes('xl');
                this.navigationAppearance = 'default';
            });
    }

    checkActiveComponent() {
        const currentRoute = this.router.url;
        this.showAreaInput = currentRoute.includes('/config/md');
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    openChats() {
        this._router.navigate(['bp', 'chat']);
    }
}
