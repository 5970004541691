<div [ngClass]="hide() ? 'invisible' : ''">
    <mat-form-field
        id="orgFilterSelector"
        *appHasType="'CORPORATE'"
        appearance="fill"
        class="my-auto min-w-40 md:w-64">
        <mat-select data-test-id="orgSelection" [formControl]="organisationForm">
            <mat-option
                *ngFor="let organisation of organisations(); trackBy: trackOrganisationById"
                [value]="organisation.organisationId">
                {{ organisation.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
