import { Injectable, signal, WritableSignal } from '@angular/core';
import {
    CreateUserRequestDto,
    DmEmployeesControllerService,
    Employee,
    UserControllerService,
} from 'app/api';
import { UserService } from 'app/core/user/user.service';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';
import { map, Observable, of, switchMap } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class EmployeeService {
    private _selectedVersion: string;

    get selectedVersion() {
        return this._selectedVersion;
    }
    set selectedVersion(selectedVersion: string) {
        this._selectedVersion = selectedVersion;
    }

    employeesWithAssignments: WritableSignal<Employee[]> = signal([]);

    employees: WritableSignal<Employee[]> = signal([]);
    private _selected: Employee;

    get selectedEmployee(): Employee {
        return this._selected;
    }

    setSelectedEmployee(employee: Employee) {
        this._selected = employee;
    }

    constructor(
        private api: DmEmployeesControllerService,
        private orgService: OrganisationFilterService,
        private userController: UserControllerService,
        private userService: UserService
    ) {}

    get availableEmployees(): Observable<Employee[]> {
        if (this.employeesWithAssignments().length) {
            return of(this.employeesWithAssignments());
        }

        const orgId = this.orgService.selectedOrganisation();

        return this.api.getAllEmployees(orgId).pipe(
            map(response => {
                this.employees.set(response);
            }),
            switchMap(() => this.getEmployeeAssignments(orgId, this.selectedVersion)),
            map(response => {
                if (this.selectedVersion) {
                    const employees = response?.orgVersions?.[this.selectedVersion]?.employees;

                    if (employees) {
                        Object.keys(employees).forEach(key => {
                            const employee = this.employees().find(e => e.employee_id === key);
                            if (employee) {
                                employee.roles = employees[key].roles;
                            }
                        });
                    }

                    this.employeesWithAssignments.set(this.employees());
                    return this.employeesWithAssignments();
                }
                return [];
            })
        );
    }

    private getEmployeeAssignments(orgId: string, versionId: string): Observable<any> {
        return this.api.getEmployeesByOrganisation(orgId, versionId);
    }

    async getEmployeeById(id: string): Promise<Employee> {
        return await this.api
            .getEmployeeById(id, this.orgService.selectedOrganisation())
            .toPromise();
    }

    async getEmployee(id: string): Promise<Employee> {
        const emp = await this.employees().find((dto: Employee) => dto.employee_id === id);
        this._selected = emp;

        return emp;
    }

    async getEmployeesByOrgId(orgId?: string): Promise<void> {
        if (!orgId) {
            orgId = this.orgService.selectedOrganisation();
        }
        const employees = await this.api.getAllEmployees(orgId).toPromise();

        this.employees.set(employees);
        this._selected = employees[0];
    }

    updateEmployee2(employee: Employee): Observable<Employee> {
        return this.api.updateEmployeeAssignments(employee.employee_id, {
            organisation: this.orgService.selectedOrganisation(),
            orgVersion: this.selectedVersion,
            roles: employee.roles,
        });
    }

    async updateEmployee(employee: Employee): Promise<void> {
        const updatedEmployee = await this.api
            .updateEmployees(employee.employee_id, employee)
            .toPromise();
        // This only works if I update my own user
        // const userFromTable = this.userService.userFromTable;
        // const user = this.userService.user;
        // user.metadata = updatedEmployee.metadata;
        // user.firstname = updatedEmployee.first;
        // user.lastname = updatedEmployee.last;
        // this.userController
        //     .updateUser(user.keycloakId, {
        //         firstName: updatedEmployee.first,
        //         lastName: updatedEmployee.last,
        //         email: updatedEmployee.email,
        //         username: updatedEmployee.user_name,
        //         authorizations: userFromTable.authorizations,
        //         enabled: userFromTable.enabled,
        //         organisations: userFromTable.organisations,
        //         metadata: updatedEmployee.metadata,
        //     })
        //     .toPromise();
        this._selected = updatedEmployee;
    }

    async connectEmployeeToUser(employeeId: string, user: CreateUserRequestDto): Promise<void> {
        let emp = await this.getEmployeeById(employeeId);
        emp.user_name = user.username;
        this.updateEmployee(emp);
    }

    async createEmployee(employee: Employee): Promise<void> {
        const newEmployee = await this.api.createEmployees(employee).toPromise();
        this._selected = newEmployee;
        this.getEmployeesByOrgId(this.orgService.selectedOrganisation());
    }

    getCurrent(): Employee {
        return this.employees()[0];
    }

    async deleteEmployee(employee: Employee): Promise<void> {
        await this.api.deleteEmployee(employee.employee_id, employee.organisation).toPromise();
        this.getEmployeesByOrgId(this.orgService.selectedOrganisation());
    }
}
