import { NgIf } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { MsTeamsNavigationBarComponent } from './ms-teams-navigation-bar/ms-teams-navigation-bar.component';

@Component({
    selector: 'view-layout',
    imports: [FuseLoadingBarComponent, NgIf, RouterOutlet, MsTeamsNavigationBarComponent],
    templateUrl: './view-layout.component.html',
    styleUrl: './view-layout.component.scss'
})
export class ViewLayoutComponent {}
