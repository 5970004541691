<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationHeader>
        <div class="max-xl:pt-2 flex flex-row items-center w-full p-4 xl:p-4 xl:pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-6 xl:w-8" src="assets/images/logo/logo.png" />
            </div>
            <!-- Components -->
            <div class="flex flex-row gap-2 md:flex-col md:items-center ml-auto">
                <languages class="md:hidden"></languages>
                <schemes class="md:hidden"></schemes>
                <apps class="md:hidden"></apps>
                <!-- <user
                    class=""
                    [showAvatar]="true"
                    [navigationAppearance]="navigationAppearance"></user> -->
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full xl:p-4">
            <div class="relative w-12 h-12 xl:w-24 xl:h-24">
                <img
                    class="w-full h-full rounded-full"
                    *ngIf="user().metadata"
                    [src]="user().metadata?.profilePicture"
                    alt="User avatar" />
                <mat-icon
                    class="icon-size-12 mx-auto xl:icon-size-24"
                    *ngIf="!user().metadata"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mb-0 xl:mt-6">
                <div
                    class="max-xl:px-2 w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{ user().firstname + ' ' + user().lastname }}
                </div>
                <div
                    class="max-xl:px-2 w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{ user().email }}
                </div>
                <user class="" [showAvatar]="true" [navigationAppearance]="'thin'"></user>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-75">
            <img class="max-w-36" src="assets/images/logo/logo-text-on-dark.png" />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="fullscreenHeight flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-10 md:h-12 xl:h-14 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <organisation-filter *ngIf="!displayProjects && !showAreaInput"></organisation-filter>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <current-org></current-org>
            <button mat-icon-button (click)="openChats()">
                <mat-icon svgIcon="heroicons_outline:chat-bubble-bottom-center-text"></mat-icon>
            </button>
            <languages class="hidden md:block"></languages>
            <apps class="hidden md:block"></apps>
            <schemes class="hidden md:block"></schemes>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <button class="hidden md:block xl:hidden" mat-icon-button (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button>
        </div>
    </div>

    <!-- Content -->
    <div class="content flex flex-col flex-auto overflow-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="footer container-x--xs">
        <span class="font-medium text-secondary text-xs"
            >AI2GID &copy; {{ currentYear }} - v{{ version }}
        </span>
    </div>
</div>

<!-- Quick chat -->
<quick-chat #quickChat="quickChat"></quick-chat>
