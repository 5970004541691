import { ChangeDetectionStrategy, Component, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Employee } from 'app/api';
import { DialogBuilderComponent } from 'app/core/dialogBuilder/dialog-builder.component';

@Component({
    selector: 'employee-details-dialog',
    imports: [MatIconModule],
    templateUrl: './employee-details-dialog.component.html',
    styleUrl: './employee-details-dialog.component.scss'
})
export class EmployeeDetailsDialogComponent extends DialogBuilderComponent<Employee> {
    constructor() {
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();
    }
    override ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }
}
