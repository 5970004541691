import { Component, OnDestroy, WritableSignal, signal } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'current-org',
    imports: [],
    templateUrl: './current-org.component.html',
    styleUrl: './current-org.component.scss'
})
export class CurrentOrgComponent implements OnDestroy {
    private _destroy: Subject<void> = new Subject();

    organisationName: WritableSignal<string> = signal('EnterpriseMind Gmbh');

    constructor(private userService: UserService) {
        this.userService.user$.pipe(takeUntil(this._destroy)).subscribe((user: User) => {
            this.organisationName.set(user.company);
        });
    }
    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }
}
