import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, KeyValuePipe, TitleCasePipe } from '@angular/common';
import {
    Input,
    WritableSignal,
    signal,
    Output,
    EventEmitter,
    ViewChild,
    Component,
    input,
    InputSignal,
    effect,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { OrganisationSubType } from '@em4cloud/organisation-tree/lib/model/model';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';

@Component({
    selector: 'app-draggable-list',
    templateUrl: './draggable-list.component.html',
    styleUrls: ['./draggable-list.component.scss'],
    imports: [
        CommonModule,
        TranslocoModule,
        FuseAlertComponent,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        FormlyModule,
        KeyValuePipe,
        TitleCasePipe,
        DragDropModule,
        MatPaginatorModule,
    ]
})
export class DraggableListComponent {
    @Input() listId: string; // Unique ID for each list
    @Input() listTitle: string;
    listData: InputSignal<OrganisationSubType[]> = input([]); // List to display (e.g., todo or done)
    @Input() pageSizeOptions = [5, 10, 25];
    @Input() pageIndex = signal(0);
    @Input() filterModel = { filter: '' };
    @Input() connectedLists: string[]; // List of IDs of connected drop lists
    @Output() dropEvent = new EventEmitter<any>();
    @Output() exitEvent = new EventEmitter<any>();

    inDevelopmentStatus = input(true);

    @ViewChild(MatPaginator) paginator: MatPaginator;

    filterForm = new FormGroup({});
    filterFields: FormlyFieldConfig[] = [
        {
            key: 'filter',
            type: 'input',
            templateOptions: {
                label: 'Filter List',
                placeholder: 'Type to filter...',
            },
            expressionProperties: {
                'model.filter': (model: any, formState: any, field: FormlyFieldConfig) => {
                    this.filterList(field.formControl.value);
                },
            },
        },
    ];

    pageSize = signal(10);
    filterValue: string;

    constructor() {}

    get paginatedList(): OrganisationSubType[] {
        const start = this.pageIndex() * this.pageSize();
        if (!this.filterValue) {
            return this.listData().slice(start, start + this.pageSize());
        } else {
            setTimeout(() => {
                this.pageIndex.set(0);
            });
            return this.listData().filter(item =>
                item.title.toLowerCase().includes(this.filterValue)
            );
        }
    }

    handlePageEvent(e: PageEvent) {
        this.pageSize.set(e.pageSize);
        this.pageIndex.set(e.pageIndex);
    }

    filterList(filterValue: string) {
        this.filterValue = filterValue;
    }

    onDrop(event: CdkDragDrop<OrganisationSubType[]>) {
        this.dropEvent.emit({ event, pageSize: this.pageSize(), pageIndex: this.pageIndex() });
    }
    exited(event) {
        this.exitEvent.emit(this.paginatedList);
    }
}
