import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { HasRoleAndPermissionGuard } from './core/auth/roles-and-permissions/guard/has-role-and-permission.guard';
import { RoleGuard } from './core/role-management/has-role.guard';
import { hasUnsavedChangesGuard } from './core/save-button/guard/has-unsaved-changes.guard';
import { TypeGuard } from './core/type-management/has-type.guard';
import { InventoryComponent } from './modules/employees/inventory/inventory.component';
import { InventoryService } from './modules/employees/inventory/inventory.service';
import { InventoryListComponent } from './modules/employees/inventory/list/inventory.component';
import {
    OrgWrapper2Component,
    OrgWrapperComponent,
} from './modules/org-sys-area/org-sys-area-wrapper/org-sys-area-wrapper.component';
import { TreeTestComponent } from './modules/ui-test/tree-test/tree-test.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/bp'
    //{ path: '', pathMatch: 'full', redirectTo: 'bp' },

    // Redirect signed-in user to the '/bp'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    //{ path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'bp' },
    // {
    //     path: 'sign-in',
    //     loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes'),
    // },

    // Auth routes for guests
    {
        path: '',
        // canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import('app/modules/auth/confirmation-required/confirmation-required.routes'),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import('app/modules/auth/forgot-password/forgot-password.routes'),
            },
            {
                path: 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes'),
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sub-sign-in',
                loadChildren: () =>
                    import('app/modules/auth/subscription-sign-in/subscription-sign-in.routes'),
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'unlock-session',
                loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes'),
            },
        ],
    },
    {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes'),
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
        ],
    },

    // MS-Teams routes
    {
        path: 'ms-teams',
        component: LayoutComponent,
        data: {
            layout: 'view',
        },
        children: [
            {
                path: '',
                loadChildren: () => import('app/modules/ms-teams/ms-teams.routes'),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },

        children: [
            // Processes
            {
                path: 'bp',
                canActivate: [HasRoleAndPermissionGuard],
                data: {
                    rolesAndPermissions: ['embpm_r'],
                },
                children: [
                    { path: '', redirectTo: 'processes', pathMatch: 'full' },
                    // Dashboards
                    {
                        path: 'dashboards',
                        children: [
                            {
                                path: 'project',
                                loadChildren: () =>
                                    import('app/modules/dashboards/project/project.routes'),
                            },
                            {
                                path: 'analytics',
                                loadChildren: () =>
                                    import('app/modules/dashboards/analytics/analytics.routes'),
                            },
                            {
                                path: 'finance',
                                loadChildren: () =>
                                    import('app/modules/dashboards/finance/finance.routes'),
                            },
                        ],
                    },
                    {
                        path: 'processes',
                        loadChildren: () =>
                            import('app/modules/bp-management/bp-management.routes'),
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        canDeactivate: [hasUnsavedChangesGuard],
                    },
                    {
                        path: 'task',
                        canActivate: [RoleGuard],
                        data: { roles: ['ADM', 'CON'] },
                        loadChildren: () => import('app/modules/task/task.routes'),
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        canDeactivate: [hasUnsavedChangesGuard],
                    },
                    {
                        path: 'view',
                        loadChildren: () => import('app/modules/view/view.routes'),
                        canDeactivate: [hasUnsavedChangesGuard],
                    },
                    {
                        path: 'master-data',
                        loadChildren: () => import('app/modules/org-sys-area/sys-area.routes'),
                    },
                    {
                        path: 'data',
                        loadChildren: () => import('app/modules/data-table/data-table.routes'),
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        canDeactivate: [hasUnsavedChangesGuard],
                    },
                    {
                        path: 'org',
                        children: [
                            {
                                path: 'organisation-structure',
                                component: OrgWrapperComponent,
                            },
                            {
                                path: 'employees',
                                component: InventoryComponent,
                                children: [
                                    {
                                        path: '',
                                        component: InventoryListComponent,
                                        resolve: {
                                            brands: () => inject(InventoryService).getBrands(),
                                            categories: () =>
                                                inject(InventoryService).getCategories(),
                                            products: () => inject(InventoryService).getProducts(),
                                            tags: () => inject(InventoryService).getTags(),
                                            vendors: () => inject(InventoryService).getVendors(),
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'employee-assignment',
                                component: OrgWrapper2Component,
                            },
                        ],
                    },
                    {
                        path: 'settings',
                        loadChildren: () => import('app/modules/settings/settings.routes'),
                    },

                    { path: 'chat', loadChildren: () => import('app/modules/chat/chat.routes') },
                    // DEMO
                    {
                        path: 'canvas',
                        loadChildren: () => import('app/modules/canvas/canvas.routes'),
                    },
                ],
            },

            // Implementation
            {
                path: 'implementation',
                canActivate: [RoleGuard, TypeGuard],
                data: { roles: ['ADM', 'CON'], types: ['CORPORATE'] },
                children: [
                    { path: '', redirectTo: 'calendar', pathMatch: 'full' },
                    {
                        path: 'tests',
                        loadChildren: () => import('app/modules/test/test.routes'),
                    },
                    {
                        path: 'verify',
                        loadChildren: () => import('app/modules/verify/verify.routes'),
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        canDeactivate: [hasUnsavedChangesGuard],
                    },
                    {
                        path: 'report',
                        loadChildren: () => import('app/modules/report/report.routes'),
                        canDeactivate: [hasUnsavedChangesGuard],
                    },
                    {
                        path: 'calendar',
                        loadChildren: () => import('app/modules/calendar/calendar.routes'),
                    },
                    {
                        path: 'project-management',
                        loadChildren: () =>
                            import('app/modules/project-management/project-management.routes'),
                    },
                ],
            },

            // Data
            {
                path: 'bim',
                canActivate: [RoleGuard, TypeGuard],
                data: { roles: ['ADM', 'CON'], types: ['CORPORATE'] },
                children: [
                    { path: '', redirectTo: 'create-rcud', pathMatch: 'full' },
                    {
                        path: 'create-rcud',
                        loadChildren: () => import('app/modules/data/data.routes'),
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        canDeactivate: [hasUnsavedChangesGuard],
                    },
                    {
                        path: 'data-rcud',
                        loadChildren: () => import('app/modules/data-table/data-table.routes'),
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        canDeactivate: [hasUnsavedChangesGuard],
                    },
                ],
            },

            // config
            {
                path: 'config',
                canActivate: [RoleGuard, TypeGuard],
                data: { roles: ['ADM', 'CON'], types: ['CORPORATE'] },
                children: [
                    // master data config
                ],
            },
            {
                path: 'project',
                canActivate: [RoleGuard, TypeGuard],
                data: { roles: ['ADM', 'CON'], types: ['CORPORATE'] },
                children: [
                    {
                        path: '',
                        redirectTo: 'management',
                        pathMatch: 'full',
                    },
                    {
                        path: 'management',
                        loadChildren: () =>
                            import(
                                'app/modules/project/management-wrapper/project-management.routes'
                            ),
                    },
                    {
                        path: 'tasks',
                        loadChildren: () => import('app/modules/project/project.routes'),
                    },
                ],
            },

            // component ui test
            {
                path: 'ui-test',
                component: TreeTestComponent,
            },

            // Forbidden due to lack of permissions or roles
            {
                path: 'forbidden',
                loadChildren: () => import('app/modules/auth/forbidden/forbidden.routes'),
            },

            {
                path: 'administration',
                loadChildren: () => import('app/modules/maintenance-page/maintenance.routes'),
            },
        ],
    },
];
