import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseConfig, FuseConfigService, Scheme } from '@fuse/services/config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'schemes',
    imports: [CommonModule, MatIconModule, MatTooltipModule, MatButtonModule, TranslocoModule],
    templateUrl: './schemes.component.html',
    styleUrls: ['./schemes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchemesComponent implements OnInit {
    config: FuseConfig;
    scheme: 'dark' | 'light' | 'auto' = 'light';

    constructor(private _fuseConfigService: FuseConfigService, private _destroyRef: DestroyRef) {}

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((config: FuseConfig) => {
                // Store the config
                this.config = config;
                this.scheme = this.config.scheme;
            });
    }

    getSchemeIcon() {
        if (this.scheme === 'light') {
            return 'wb_sunny';
        } else {
            return 'brightness_3';
        }
    }

    changeScheme() {
        if (this.scheme === 'light') this.scheme = 'dark';
        else this.scheme = 'light';
        this.setScheme(this.scheme);
    }

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
    setScheme(scheme: Scheme): void {
        this._fuseConfigService.config = { scheme };
        this.updateConfigInLocalStorage('scheme', scheme);
    }

    /**
     * Sync with local storage
     *
     */
    updateConfigInLocalStorage(key: string, value: any) {
        let config: any = localStorage.getItem('fuseConfig') ?? '{}';
        try {
            config = JSON.parse(config);
            config[key] = value;
            localStorage.setItem('fuseConfig', JSON.stringify(config));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
