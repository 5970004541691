import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, WritableSignal, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoService } from '@ngneat/transloco';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ButtonType } from 'app/core/dialogBuilder/dialog-builder.models';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { Subject } from 'rxjs';
import { SelectJiraAccountsDialogComponent } from '../select-jira-accounts-dialog/select-jira-accounts-dialog.component';
import { SelectJiraAccountService } from './select-jira-account.service';

@Component({
    selector: 'select-jira-accounts-form-template',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule,
        MatFormFieldModule,
        MatButtonModule,
    ],
    templateUrl: './select-jira-accounts-form-template.component.html',
    styleUrl: './select-jira-accounts-form-template.component.scss'
})
export class SelectJiraAccountsFormTemplateComponent
    extends FieldType
    implements OnInit, OnDestroy
{
    private _destroy: Subject<void> = new Subject();

    private dialog: MatDialogRef<any, any>;

    protected _translocoContent = signal(null);

    private executeActionSignal: WritableSignal<string> = signal(null);

    data: WritableSignal<any> = signal([]);
    labelProp: string;

    constructor(
        private dialogService: DialogBuilderService,
        private _translocoService: TranslocoService,
        private selectJiraAccountService: SelectJiraAccountService
    ) {
        super();

        this._translocoService.selectTranslation('bp').subscribe(content => {
            this._translocoContent.set(content);
        });
    }

    ngOnInit(): void {
        this.setInitialData();
    }
    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    setInitialData(): void {
        this.labelProp = this.field.props.labelProp;
        const formControlValue = this.formControl.getRawValue();

        if (formControlValue) {
            this.data.set(formControlValue);
        }
    }

    closeAction(now: Date): void {
        this.executeActionSignal.set(null);
    }

    selectAccounts(): void {
        this.selectJiraAccountService.selection = this.data();

        this.dialog = this.dialogService.openDialog({
            descriptor: {
                header: {
                    title: this._translocoContent()['selectAreaDialogTitle'],
                    showCloseButton: true,
                },
                content: {
                    componentConfig: {
                        component: SelectJiraAccountsDialogComponent,
                        componentData: {
                            model: {},
                            selection: this.data(),
                            executeActionSignal: this.executeActionSignal,
                        },
                    },
                },
                actions: {
                    dialogActions: [
                        {
                            code: 'cancel',
                            style: ButtonType.simple,
                            title: this._translocoContent()['cancel'],
                            dismiss: true,
                        },
                        {
                            code: 'selectAccount',
                            style: ButtonType.raised,
                            color: 'primary',
                            title: this._translocoContent()['selectAccount'],
                        },
                    ],
                },
                executeActionSignal: this.executeActionSignal,
                dialogSize: 'fullscreen',
            },
        });

        this.dialog.afterClosed().subscribe(() => {
            this.formControl.setValue(this.selectJiraAccountService.selection);
            this.data.set(this.selectJiraAccountService.selection);
            this.form.markAsDirty();
        });
    }
}
