import { NgIf } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit, effect } from '@angular/core';
import { NavigationEnd, Route, Router, RouterOutlet } from '@angular/router';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { OAuthErrorEvent, OAuthEvent, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { appRoutes } from 'app/app.routes';
import { Subject } from 'rxjs';
import { AuthService } from './core/auth/auth.service';
import { LoadingSpinnerComponent } from './core/loading-spinner/loading-spinner/loading-spinner.component';
import { LoadingService } from './core/loading-spinner/loading.service';
import { UserType } from './core/type-management/user.type';
import { UserService } from './core/user/user.service';
import { DenseService } from './layout/layouts/vertical/dense/dense.service';
import { StorageService } from './services/storage/storage.service';
import { XmlInterceptor } from './services/xml/xml-interceptor.service';
import { YamlService } from './services/yaml.service';
import { AuthConfigService } from './sso.config';
// import { environment } from 'environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterOutlet, LoadingSpinnerComponent, NgIf, TranslocoModule],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'apiError' }]
})
export class AppComponent implements OnInit, OnDestroy {
    private _destroy: Subject<void> = new Subject();

    xmlInterceptor: XmlInterceptor;

    private mainPaths: string[] = [];

    showSpinner: boolean = false;

    constructor(
        private router: Router,
        private _inject: Injector,
        private _authService: AuthService,
        private oauthService: OAuthService,
        private _denseService: DenseService,
        private _loadingService: LoadingService,
        private storageService: StorageService,
        private _yamlService: YamlService,
        private _userService: UserService,
        private authConfigService: AuthConfigService
    ) {
        this.collectMaintPaths();

        if (!localStorage.getItem('organisation')) {
            localStorage.setItem('organisation', '');
        }

        effect(() => {
            this.showSpinner = this._loadingService.getLoadingStatus();
        });
    }

    injectXmlInterceptor(): void {
        this._authService.check().subscribe(authenticated => {
            if (authenticated) {
                // only instantiage when user is logged in
                // because it calls services which only an authenticated user should be able to access
                // otherwise will create bugs
                this.xmlInterceptor = this._inject.get(XmlInterceptor);
            }
        });
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    ngOnInit(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (
                    !event.urlAfterRedirects.includes('/ms-teams') &&
                    !event.urlAfterRedirects.includes('/sub-sign-in')
                ) {
                    const client = this.storageService.getClientId(); //localStorage.getItem('clientId');
                    if (client) {
                        this.configureOAuth(client);
                        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
                            this._authService.getUser().subscribe(res => {
                                if (res && !this.hasIncludedPart(this.router.url, this.mainPaths)) {
                                    this.router.navigate(['/bp']);
                                }
                            });
                            const token = sessionStorage.getItem('access_token');
                            if (token) {
                                localStorage.setItem('access_token', token);
                            }
                            this.injectXmlInterceptor();
                        });
                    } else {
                        this.router.navigate(['/sign-in']);
                    }

                    this.oauthService.events.subscribe((event: OAuthEvent) => {
                        if (event instanceof OAuthSuccessEvent) {
                            if (event.type === 'token_received') {
                                const token = sessionStorage.getItem('access_token');
                                if (token) {
                                    localStorage.setItem('access_token', token);
                                }
                            }
                        } else if (event instanceof OAuthErrorEvent) {
                            if (event.type === 'token_refresh_error') {
                                console.error('Error refresh token', event);
                            }
                        }
                    });
                }
            }
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (event.urlAfterRedirects === '/bp/calendar-ms-teams') {
                    this._denseService.displayHeaderAndNav = false;
                } else {
                    this._denseService.displayHeaderAndNav = true;
                }
            }
        });

        this._yamlService.getBackendData();
    }

    private collectMaintPaths(): void {
        appRoutes.forEach((route: Route) => {
            if (route.path?.length) {
                this.mainPaths.push(route.path);
            }
            if (route.children?.length) {
                route.children.forEach((childRoute: Route) => {
                    if (childRoute.path?.length) {
                        this.mainPaths.push(childRoute.path);
                    }
                });
            }
        });
    }

    private hasIncludedPart(str: string, list: string[]): boolean {
        for (const listItem of list) {
            if (str.includes(listItem)) {
                return true;
            }
        }
        return false;
    }

    private configureOAuth(clientId: string): void {
        const type = this._userService.user?.userType;
        const idToken = this.oauthService.getIdToken();

        const idTokenFromLocalStorage = localStorage.getItem('id_token');
        if (idTokenFromLocalStorage === 'null') {
            localStorage.setItem('id_token', idToken);
        }

        let config = this.authConfigService.authConfig;
        config.logoutUrl = `${config.issuer}/realms/${clientId}/protocol/openid-connect/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(config.postLogoutRedirectUri)}`;
        if (type === UserType.SUBSCRIPTION)
            config.postLogoutRedirectUri = config.postLogoutRedirectUri;

        if (!config.issuer.endsWith(clientId)) {
            config.issuer = config.issuer + '/realms/' + clientId;
        }
        this.oauthService.configure(config);
        this.oauthService.setupAutomaticSilentRefresh();
    }
}
