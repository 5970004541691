<div *transloco="let t; read: 'bpOrganisation'">
    @if (!selectedItem()) {
        <fuse-alert
            [appearance]="'soft'"
            [type]="'basic'"
            [showIcon]="false"
            class="text-center block my-auto p-2 w-full">
            {{ t('selectitem') }}
        </fuse-alert>
    } @else {
        @if (fields()) {
            <mat-tab-group class="flex-1 w-full h-full">
                <mat-tab [label]="t('details')">
                    <ng-template matTabContent>
                        <form class="p-2" [formGroup]="form" (ngSubmit)="submit()">
                            <formly-form
                                [model]="model()"
                                [fields]="fields()"
                                [form]="form"></formly-form>
                        </form>
                    </ng-template>
                </mat-tab>
                <mat-tab *ngIf="showLogo()" [label]="t('logo')">
                    <ng-template matTabContent>
                        <div class="flex flex-col gap-2">
                            @if (selectedItem().metadata?.logo) {
                                <img
                                    class="mx-auto w-2/3 rounded"
                                    src="{{ selectedItem().metadata.logo }}"
                                    alt="logo" />
                            } @else {
                                <fuse-alert
                                    [appearance]="'soft'"
                                    [type]="'basic'"
                                    [showIcon]="false"
                                    class="text-center block my-auto p-2 w-full">
                                    {{ t('noLogoAvailable') }}
                                </fuse-alert>
                            }
                            <div
                                *ngIf="selectedItem().status === 'DEVELOPMENT'"
                                class="flex flex-row gap-2">
                                <div
                                    class="w-full max-w-lg mx-auto p-4 border-2 border-dashed border-gray-300 rounded-lg">
                                    <div
                                        appDragDrop
                                        (fileDropped)="onFileDropped($event)"
                                        class="relative flex justify-center items-center py-2 cursor-pointer">
                                        <div *ngIf="!imageSrc" class="text-center">
                                            <p class="text-sm text-gray-600">
                                                {{ t('dragAndDrop') }}
                                            </p>
                                            <p class="text-sm text-gray-600">
                                                {{ t('or') }}
                                            </p>
                                            <button
                                                type="button"
                                                class="bg-blue-500 text-white px-4 py-2 rounded"
                                                (click)="fileInput.click()">
                                                {{ t('selectFile') }}
                                            </button>
                                            <input
                                                #fileInput
                                                type="file"
                                                class="hidden"
                                                (change)="onFileSelected($event)"
                                                accept="image/*" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab *ngIf="showHeritants()" [label]="t('heritants')">
                    <ng-template matTabContent>
                        <custom-table
                            #customTable
                            [displayedColumns]="displayedColumns()"
                            [dataSource]="dataSource()"
                            [selectedRows]="selection()"
                            [enableRowSelection]="true"
                            [enablePagination]="true"
                            [enableMultipleRowSelection]="true"
                            (rowsWithSelectedData)="handleSelectedRows($event)"
                            (onRowsSelected)="rowSelectionChange($event)"></custom-table>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        }
    }
</div>
