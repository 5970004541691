import { Overlay } from '@angular/cdk/overlay';
import {
    Component,
    OnDestroy,
    OnInit,
    Signal,
    SimpleChanges,
    WritableSignal,
    computed,
    signal,
} from '@angular/core';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import {
    GridViewsModule,
    TableColumnDef,
} from '../../../../../../../../../projects/grid-views/src/public-api';
import { SelectJiraAccountService } from '../select-jira-accounts-form-template/select-jira-account.service';
import { DialogBuilderComponent } from 'app/core/dialogBuilder/dialog-builder.component';
import { DmCostElementsService } from 'app/api';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'select-jira-accounts-dialog',
    imports: [GridViewsModule],
    templateUrl: './select-jira-accounts-dialog.component.html',
    styleUrl: './select-jira-accounts-dialog.component.scss',
    providers: [
        {
            provide: MAT_SELECT_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.reposition(),
            deps: [Overlay],
        },
    ]
})
export class SelectJiraAccountsDialogComponent
    extends DialogBuilderComponent<any>
    implements OnInit, OnDestroy
{
    private destroy$ = new Subject<void>();

    data: WritableSignal<any> = signal([]);
    displayedColumns: WritableSignal<Array<TableColumnDef>> = signal([]);
    dataSource: Signal<MatTableDataSource<any>>;

    jiraAccounts: Array<JiraAccount> = [
        {
            glAccount: '10010',
            glsText: 'Acc. Req.',
        },
        {
            glAccount: '10011',
            glsText: 'Acc. Req.',
        },
        {
            glAccount: '10012',
            glsText: 'Acc. Req.',
        },
        {
            glAccount: '410001',
            glsText: 'Sales R.A.',
        },
        {
            glAccount: '410002',
            glsText: 'Sales R.A.',
        },
        {
            glAccount: '410003',
            glsText: 'Sales R.A.',
        },
    ];

    constructor(
        private selectJiraAccountService: SelectJiraAccountService,
        private service: DmCostElementsService,
        private orgService: OrganisationFilterService
    ) {
        super();
        // this.data.set(this.jiraAccounts);
        this.displayedColumns.set([
            {
                columnName: 'glAccount',
                columnKey: 'glAccount',
                contentType: 'string',
                enableSorting: true,
            },
            {
                columnName: 'glSText',
                columnKey: 'glSText',
                contentType: 'string',
                enableSorting: true,
            },
        ]);

        this.service
            .getAllCostElements(this.orgService.selectedOrganisation(), true)
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.data.set(res);
            });

        this.dataSource = computed(
            () => new MatTableDataSource(this.data()) ?? new MatTableDataSource([])
        );
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.selection = this.componentData.selection;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    selection: any[] = [];

    onRowSelected($event: any): void {
        this.selection = $event;
        this.selectJiraAccountService.selection = $event;
    }
}

export interface JiraAccount {
    organisationId?: string;
    chartOfAccounts?: string;
    glAccount: string;
    glsText: string;
    gllText?: string;
    description?: string;
    alternativeAccount?: string;
    asText?: string;
    type?: string;
    accountGroup?: string;
    costElementCat?: string;
    automatic?: boolean;
    costElementGroup?: string;
    costElementGroupText?: string;
}
