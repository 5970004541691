import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    Signal,
    ViewChild,
    ViewEncapsulation,
    WritableSignal,
    computed,
    effect,
    signal,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { fuseAnimations } from '@fuse/animations';
import { TRANSLOCO_SCOPE, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { Employee, UploadFileService } from 'app/api';
import { ApiUploadFilePost200Response } from 'app/api/model/apiUploadFilePost200Response';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';
import { UserSelectDialogComponent } from 'app/modules/maintenance-page/user-select-dialog/user-select-dialog.component';
import { Subject, takeUntil } from 'rxjs';
import { EmployeeService } from '../../employee.service';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { InventoryPagination } from '../inventory.types';

@Component({
    selector: 'inventory-list',
    templateUrl: './inventory.component.html',
    styles: [
        /* language=SCSS */
        `
            .inventory-grid {
                grid-template-columns: 48px auto 40px;

                @screen sm {
                    grid-template-columns: 48px 112px 112px 112px auto 48px 48px;
                }

                @screen md {
                    grid-template-columns: 48px 112px 112px 112px auto 48px 48px;
                }

                @screen lg {
                    grid-template-columns: 48px 112px 112px 112px auto 48px 48px;
                }
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    imports: [
        NgIf,
        MatProgressBarModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatSortModule,
        NgFor,
        NgTemplateOutlet,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatOptionModule,
        MatCheckboxModule,
        MatRippleModule,
        ImageUploadComponent,
        ReactiveFormsModule,
        MatInputModule,
        FormlyModule,
        FormlyMaterialModule,
        TranslocoModule,
        MatTooltipModule,
    ],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'employees' }],
})
export class InventoryListComponent implements OnDestroy {
    @ViewChild(MatPaginator) private _paginator: MatPaginator;

    // employees$: Signal<Employee[]>;
    paginatedEmployees$: WritableSignal<Employee[]> = signal([]);
    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = false;
    searchInputControl: FormControl;
    selectedEmployee: Employee | null = null;
    selectedEmployeeForm: FormGroup = new FormGroup({});
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    fields: WritableSignal<FormlyFieldConfig[]> = signal([]);
    filterText = signal('');
    translocoContent: WritableSignal<any> = signal({});
    pagination: InventoryPagination;

    pageSize = 5;
    pageIndex = 0;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        public employeeService: EmployeeService,
        private dialog: MatDialog,
        private orgService: OrganisationFilterService,
        private uploadFileService: UploadFileService,
        private translocoService: TranslocoService
    ) {
        this.translocoService
            .selectTranslation('employees')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(content => {
                this.translocoContent.set(content);
            });

        this.searchInputControl = new FormControl();

        this.searchInputControl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                this.filterText.set(value.toLowerCase());
            });

        effect(() => {
            if (this.translocoContent()) this.createFormField();
        });

        effect(() => {
            if (this.orgService.selectedOrganisation()) {
                this.employeeService.getEmployeesByOrgId(orgService.selectedOrganisation());
            }
        });

        // this.employees$ = computed(() => {
        //     let employees = this.employeeService.employees().filter((emplyoee: Employee) => {
        //         return (
        //             emplyoee.user_name.includes(this.filterText()) ||
        //             emplyoee.email.includes(this.filterText())
        //         );
        //     });

        //     return employees;
        // });

        effect(() => {
            this.updatePaginatedList();
        }, {});

        effect(() => {
            const string = this.filterText();

            this.paginatedEmployees$.set(
                this.employeeService
                    .employees()

                    .filter((emplyoee: Employee) => {
                        return (
                            emplyoee.user_name.includes(this.filterText()) ||
                            emplyoee.email.includes(this.filterText())
                        );
                    })
            );
        });
    }

    // Method to update the paginated list based on the current page
    updatePaginatedList(): void {
        const startIndex = this.pageIndex * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        this.paginatedEmployees$.set(
            this.employeeService
                .employees()

                .filter((emplyoee: Employee) => {
                    return (
                        emplyoee.user_name.includes(this.filterText()) ||
                        emplyoee.email.includes(this.filterText())
                    );
                })
                .slice(startIndex, endIndex)
        );
    }

    // Handle page change
    onPageChange(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.updatePaginatedList();
    }

    /**
     * Handles file drop event from the image upload component.
     *
     * When a file is dropped, it is uploaded to the server and the metadata of the selectedEmployee is updated with the url of the uploaded file.
     * Furthermore, the employees list is updated to reflect the new metadata of the selected employee.
     * @param fileList - The dropped file list
     */
    onFileDropped(fileList: FileList) {
        Array.from(fileList).forEach(file => {
            this.uploadFileService
                .backendApiUploadFilePost('thumbnail', file, 'PICTURE')
                .subscribe((data: ApiUploadFilePost200Response) => {
                    this.selectedEmployee.metadata = data.url;
                    this.employeeService.employees.update((employees: Employee[]) => {
                        return employees.map((employee: Employee) => {
                            if (employee.employee_id === this.selectedEmployee.employee_id) {
                                employee.metadata = data.url;
                            }
                            return employee;
                        });
                    });
                });
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Toggles the details of an employee by id. If the employee details are already shown, it will be closed.
     * If the employee details are not shown, it will be loaded and shown.
     * @param id - The id of the employee for which to show the details
     */
    async toggleDetails(id: string): Promise<void> {
        if (this.selectedEmployee && this.selectedEmployee.employee_id === id) {
            this.closeDetails();
            return;
        }

        const emp = await this.employeeService.getEmployee(id);

        this.selectedEmployee = emp;

        this.selectedEmployeeForm.patchValue(emp);
        this.createFormField();
    }

    private createFormField(): void {
        this.fields.set([
            {
                fieldGroupClassName: 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full',
                fieldGroup: [
                    {
                        type: 'input',
                        key: 'organisation',
                        props: {
                            label: this.translocoContent()['form.organisation'],
                            required: true,
                            disabled: true,
                        },
                    },
                    {
                        type: 'input',
                        key: 'pre',
                        props: {
                            label: this.translocoContent()['form.pre'],
                            required: false,
                        },
                    },
                    {
                        type: 'input',
                        key: 'first',
                        props: {
                            label: this.translocoContent()['form.first'],
                            required: true,
                        },
                    },
                    {
                        type: 'input',
                        key: 'last',
                        props: {
                            label: this.translocoContent()['form.last'],
                            required: true,
                        },
                    },
                    {
                        type: 'input',
                        key: 'email',
                        props: {
                            label: this.translocoContent()['form.email'],
                            required: true,
                        },
                    },
                    {
                        type: 'input',
                        key: 'phone',
                        props: {
                            label: this.translocoContent()['form.phone'],
                            required: false,
                        },
                    },
                    {
                        type: 'input',
                        key: 'department',
                        props: {
                            label: this.translocoContent()['form.department'],
                            required: false,
                        },
                    },
                    {
                        type: 'input',
                        key: 'position',
                        props: {
                            label: this.translocoContent()['form.position'],
                            required: false,
                        },
                    },
                    {
                        type: 'input',
                        key: 'notes',
                        props: {
                            label: this.translocoContent()['form.notes'],
                            required: false,
                        },
                    },
                ],
            },
        ]);
    }

    /**
     * Close the details
     */
    closeDetails(): void {
        this.selectedEmployee = null;
    }

    /**
     * Cycle through images of selected product
     */
    cycleImages(forward: boolean = true): void {
        // Get the image count and current image index
        const count = this.selectedEmployeeForm.get('images').value.length;
        const currentIndex = this.selectedEmployeeForm.get('currentImageIndex').value;

        // Calculate the next and previous index
        const nextIndex = currentIndex + 1 === count ? 0 : currentIndex + 1;
        const prevIndex = currentIndex - 1 < 0 ? count - 1 : currentIndex - 1;

        // If cycling forward...
        if (forward) {
            this.selectedEmployeeForm.get('currentImageIndex').setValue(nextIndex);
        }
        // If cycling backwards...
        else {
            this.selectedEmployeeForm.get('currentImageIndex').setValue(prevIndex);
        }
    }

    /**
     * Create a new employee
     *
     * Creates a new employee with the default values and adds it to the list of employees
     */
    createEmplyoee(): void {
        let employeesWithNew = [
            {
                organisation: this.orgService.selectedOrganisation(),
                pre: '',
                first: '',
                last: '',
                abbr: '',
                metadata: '',
                roles: [],
                lang: '',
                address: '',
                email: '',
                phone: '',
                department: '',
                position: '',
                notes: '',
                infotypes: '',
                employee_id: '',
                user_name: '',
            },
            ...this.employeeService.employees(),
        ];
        this.employeeService.employees.set(employeesWithNew);
    }

    /**
     * Show flash message
     */
    showFlashMessage(type: 'success' | 'error'): void {
        // Show the message
        this.flashMessage = type;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Hide it after 3 seconds
        setTimeout(() => {
            this.flashMessage = null;

            // Mark for check
            this._changeDetectorRef.markForCheck();
        }, 3000);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    /**
     * Save or update the selected employee.
     *
     * This function uses the employee service to either create a new employee
     * or update an existing one. If the selected employee has an `employee_id`,
     * this function will update the existing employee. If the selected employee
     * does not have an `employee_id`, this function will create a new employee.
     */
    saveOrUpdateEmployee(): void {
        if (this.selectedEmployee.employee_id) {
            this.employeeService.updateEmployee(this.selectedEmployee);
        } else {
            this.employeeService.createEmployee(this.selectedEmployee);
        }
    }

    /**
     * Remove the selected employee.
     *
     * This function deletes the selected employee using the employee service.
     * It is an asynchronous operation and returns a Promise that resolves when
     * the deletion is complete.
     */
    async remove(): Promise<void> {
        await this.employeeService.deleteEmployee(this.selectedEmployee);
    }

    /**
     * Sort employees by different columns
     *
     * @param sort The sort object from the Angular Material Data Table
     */
    sortEmployees(sort: Sort) {
        const data = this.employeeService.employees().slice();

        const sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'user_name':
                    return this.compare(a.user_name, b.user_name, isAsc);
                case 'firstname':
                    return this.compare(a.first, b.first, isAsc);
                case 'lastname':
                    return this.compare(a.last, b.last, isAsc);
                case 'email':
                    return this.compare(a.email, b.email, isAsc);
                default:
                    return 0;
            }
        });

        this.employeeService.employees.set(sortedData);
    }

    private compare(a: string, b: string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
