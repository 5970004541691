<ng-container *transloco="let t">
    <!-- Button -->
    @if (navigationAppearance() === 'thin') {
        <button
            class="mx-auto"
            mat-icon-button
            data-test-id="settingsButton"
            (click)="openSettings()"
            [matTooltip]="t('settings')">
            <mat-icon svgIcon="feather:settings"></mat-icon
        ></button>
        <button
            data-test-id="signOutButton"
            class="mx-auto"
            mat-icon-button
            (click)="signOut()"
            [matTooltip]="t('signOut')">
            <mat-icon svgIcon="feather:log-out"></mat-icon>
        </button>
    } @else {
        <button mat-icon-button [matMenuTriggerFor]="userActions" id="profile-button">
            <span class="profile-initials">
                {{ user.firstname[0] + user.lastname[0] | uppercase }}
            </span>
        </button>

        <!-- Menu -->
        <mat-menu [xPosition]="'before'" #userActions="matMenu">
            <button mat-menu-item>
                <span class="flex flex-col leading-none">
                    <span class="font-normal">{{ t('signedInAs') }}</span>
                    <span class="mt-1.5 text-md font-medium">{{
                        user.firstname + ' ' + user.lastname
                    }}</span>
                </span>
            </button>
            <mat-divider class="my-2"></mat-divider>
            <button mat-menu-item (click)="openSettings()">
                <mat-icon svgIcon="feather:settings"></mat-icon>
                <span>{{ t('settings') }}</span>
            </button>
            <mat-divider class="my-2"></mat-divider>
            <button mat-menu-item (click)="signOut()">
                <mat-icon svgIcon="feather:log-out"></mat-icon>
                <span>{{ t('signOut') }}</span>
            </button>
        </mat-menu>
    }
</ng-container>
