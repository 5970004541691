import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { EnvironmentProviders, Provider, inject, provideEnvironmentInitializer } from '@angular/core';
import { urlInterceptor } from './url.interceptor';
import { UrlBuilderService } from './url-builder.service';

export const provideUrl = (): Array<Provider | EnvironmentProviders> => {
    return [
        provideHttpClient(withInterceptors([urlInterceptor])),
        provideEnvironmentInitializer(() => inject(UrlBuilderService)),
    ];
};
