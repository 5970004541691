import { UpperCasePipe } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Input,
    OnDestroy,
    OnInit,
    Signal,
    ViewEncapsulation,
    input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { RoleService } from 'app/core/role-management/role-management.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    encapsulation: ViewEncapsulation.None,
    exportAs: 'user',
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        UpperCasePipe,
        TranslocoModule,
        MatTooltipModule,
    ],
})
export class UserComponent implements OnInit, OnDestroy {
    @Input() showAvatar: boolean = true;

    navigationAppearance: Signal<'default' | 'dense'> = input('default');
    user: User;

    role: 'Consultant' | 'User';
    switchTo: 'Consultant' | 'User';

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private _destroyRef: DestroyRef,
        private roleService: RoleService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((user: User) => {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    openSettings(): void {
        this._router.navigate(['bp', 'settings']);
    }
}
